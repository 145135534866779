<template>
    <main class="features-admin-content">
        <h2>Solicitações de clientes</h2>
        <hr/>
        <div class="row">
            <div class="col-6">
                <b-form-group>
                    <b-form-input 
                        placeholder="Digite o título, trecho do detalhe, nome do usuário ou clínica"
                        v-model="search"
                        @input="debouncingSearch"
                    />
                </b-form-group>
            </div>
            <div class="col-6">
                <b-form-group>
                    <multiselect
                        :multiple="true"
                        v-model="status"
                        label="text" 
                        track-by="value"
                        :options="[
                            { value: 'under-review', text: 'Em revisão' },
                            { value: 'approved', text: 'Aprovado' },
                            { value: 'declined', text: 'Recusado' },
                            { value: 'in-progress', text: 'Em progresso' },
                            { value: 'completed', text: 'Concluído' },
                        ]"
                        :close-on-select="false"
                        :clear-on-select="false"
                        :showLabels="false"
                        :loading="loading"
                        placeholder="Filtrar por status"
                        class="with-border"
                        @select="debouncingSearch"
                        @remove="debouncingSearch"
                    >
                        <template slot="caret">
                            <div class="chevron">
                                <ChevronDown />
                            </div>
                        </template>
                        <template slot="selection" slot-scope="{ values, isOpen }">
                            <span class="multiselect__single" v-if="values.length" v-show="!isOpen">
                                {{ values.map(({ text }) => text).join(', ') }}
                            </span>
                        </template>
                        <template slot="option" slot-scope="{ option }">
                            <div class="feature-status">
                                <div class="status" :class="parseStatusClass(option.value)"/>
                                {{ option.text }}
                            </div>
                        </template>
                    </multiselect>
                    <div v-if="status.length" class="clear">
                        <span @click="clearStatusFilter">Limpar filtro</span>
                    </div>
                </b-form-group>
            </div>
        </div>

        <div class="scroll">
            <div class="feature" v-for="feature in features" :key="feature.id">
                <div class="feature-header">
                    <div class="upvotes">
                        {{ feature.upvotes }} votos
                    </div>
                    <multiselect
                        label="text" 
                        track-by="value"
                        :value="statusOptions.find(({ value }) => value === feature.status)"
                        :options="statusOptions"
                        :allowEmpty="false"
                        :showLabels="false"
                        placeholder="Selecionar status"
                        class="with-border status-select"
                        @select="option => changeStatus(feature, option.value)"
                    >
                        <template slot="caret">
                            <div class="chevron">
                                <ChevronDown />
                            </div>
                        </template>
                        <template slot="singleLabel" slot-scope="{ option }">
                            <div class="feature-status">
                                <div class="status" :class="parseStatusClass(option.value)"/>
                                {{ option.text }}
                            </div>
                        </template>
                        <template slot="option" slot-scope="{ option }">
                            <div class="feature-status">
                                <div class="status" :class="parseStatusClass(option.value)"/>
                                {{ option.text }}
                            </div>
                        </template>
                    </multiselect>
                </div>
                <div class="feature-title">
                    {{ feature.title }}
                </div>
                <div class="feature-details">
                    {{ feature.details }}
                </div>
                <div class="person-name">
                    <Avatar size="20" :src="feature.person?.picture" />
                    {{ feature.person?.name }} - {{ feature.person?.clinic?.name }}
                </div>
            </div>
            <div v-if="!loading && !features.length" class="empty-list">
                <h4>Nada por aqui ainda</h4>
            </div>
        </div>

        <div class="pagination">
            <b-pagination
                v-model="page"
                :total-rows="total"
                :per-page="per_page"
                first-number
                last-number
                size="sm"
            />
        </div>
    </main>    
</template>
<script>
import api from '../../api'
import { debounce } from 'lodash'
import { parseStatusClass, parseDate, parseStatus } from '../../utils/helper'

export default {
    components: {
        Avatar: () => import('@/components/General/Avatar'), 
        ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    },
    data() {
        return {
            loading: false,
            search: null,
            status: [],
            page: 1,
            per_page: 0,
            total: 0,
            debouncingSearch: debounce(this.searchDebounce, 300),
            features: [],
            statusOptions: [
                { value: 'under-review', text: 'Em revisão' },
                { value: 'approved', text: 'Aprovado' },
                { value: 'declined', text: 'Recusado' },
                { value: 'in-progress', text: 'Em progresso' },
                { value: 'completed', text: 'Concluído' },
            ]
        }
    },
    mounted() {
        this.searchDebounce()
    },
    methods: {
        parseStatusClass,
        parseDate ,
        parseStatus,
        searchDebounce() {
            this.loading = true
            api.getPaginatedFeatureRequests(this.page, this.search, this.status?.map(({ value }) => value))
            .then(({ data }) => {
                this.features = data.data
                this.per_page = data.per_page
                this.total = data.total
            })
            .catch((error) => console.log(error))
            .finally(() => (this.loading = false))
        },
        changeStatus(feature, status) {
            this.loading = true
            api.updateFeatureRequest(feature.id, { status })
            .then(() => {
                feature.status = status
                this.$toast.success('Status atualizado com sucesso!')
            })
            .catch((error) => this.$toast.error(error.message))
            .finally(() => (this.loading = false))
        },
        clearStatusFilter() {
            this.status = []
            this.searchDebounce()
        }
    }
}
</script>
<style lang="scss" scoped>
    .features-admin-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 2rem;
        height: 100vh;
    }

    .scroll {
        height: 100%;
        overflow: auto;
        padding-bottom: 10px;
    }
    .feature {
        border: 1px solid var(--neutral-200);
        border-radius: 10px;
        margin-bottom: 1rem;
        padding: 1rem;
    }
    .feature-header {
        width: 100%;
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        .upvotes {
            font-weight: 700;
            font-size: 1.2rem;
        }
    }
    .clear {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        span {
            font-size: 0.7rem;
            color: var(--type-placeholder);
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    .feature-status {
        display: flex;
        align-items: center;
        font-size: 1rem;
        font-weight: 500;

        .status {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            margin-right: 5px;
            &.under-review {
                background-color: #cbcbcb;
            }

            &.approved {
                background-color: #4CAF50;
            }
            &.declined {
                background-color: #F44336;
            }

            &.in-progress {
                background-color: #2196F3;
            }
            &.completed {
                background-color: #0a28ef;
            }
        }
    }
    .status-select {
        width: 300px;
    }
    .feature-title {
        font-size: 1.2rem;
        font-weight: 600;
        color: #424242;
    }
    .feature-details {
        font-size: 0.8rem;
        color: #424242;
    }
    .person-name {
        display: flex;
        gap: 5px;
        color: var(--type-placeholder);
        font-size: 0.8rem;
        margin-top: 10px;
    }
    .pagination {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        padding: 1rem;
        box-shadow: 0px -10px 15px white;
    }
    .empty-list {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--type-placeholder);
    }
</style>