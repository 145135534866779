import { base } from '@/utils/api'

const ENDPOINT = '/feature-requests'

export default {
    getFeatureRequests(personId, offset, limit, search) {
        const config = {
            params: { personId, offset, limit, search }
        };
        return base.get(`${ENDPOINT}`, config);
    },
    getPaginatedFeatureRequests(page, search, status) {
        const config = {
            params: { page, search, status }
        };
        return base.get(`${ENDPOINT}/paginated`, config);
    },
    createFeatureRequests(data) {
        return base.post(`${ENDPOINT}`, data)
    },
    updateFeatureRequest(id, data) {
        return base.put(`${ENDPOINT}/${id}`, data)
    },
    deleteFeatureRequests(id) {
        return base.delete(`${ENDPOINT}/${id}`)
    },
    upvoteFeatureRequest(personId, featureId) {
        return base.put(`${ENDPOINT}/${personId}/${featureId}/upvote`)
    },
}