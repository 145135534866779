import moment from 'moment'

export const parseStatusClass = status => {
    return ({ [status]: true })
}

export const parseDate = date => {
    return moment(date).format('DD [de] MMMM [de] YYYY')
}

export const parseStatus = status => {
    const statuses = {
        'under-review': 'Em revisão',
        'approved': 'Aprovado - Em votação',
        'declined': 'Recusado',
        'in-progress': 'Em progresso',
        'completed': 'Concluído',
    }
    return statuses[status] ?? status
}